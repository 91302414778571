/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Open+Sans:wght@400;600&display=swap');
body{
    position: relative;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
}
h1,h2,h3,h4,h5,h6{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}
.header-area
{
display: flex;
padding: 20px;
justify-content: space-between;
background: #F7F7F7;
min-width: 738px;
box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.25);
}
.left-side-mail-box
{
width: 350px;
min-height: 100vh;
display: block;
position: relative;
background: #553B85;
}
.inner-content-style
{
position: relative;
display: block;
}
.header-inbox
{
display: flex;
padding:10px;
gap: 20px;
height: 80px;
justify-content: center;
align-items: center;
border-bottom: 1px solid #8C62DB;
}
.header-inbox h3
{
color: #fff;
font-size: 18px;
margin: 0px;
}
.mail-icon,
.logout-icon
{
width: 20px;
margin-right: 5px;

}

.mail-notify
{
width: 20px;
height: 20px;
margin-top: 10px;

}

.logout-btn
{
border: none;
background: none;
cursor: pointer;
margin-top: 7px;
}
.logout-btn a{
color: #000;
font-size: 18px;
font-weight: 600;
text-decoration: none;
cursor: pointer;
}
.logout-btn a:hover{
color: #553B85;

}
.notification
{
display: flex;


}
.notification span{
width: 30px;
height: 30px;
background: #FF3131;
color: #fff;
font-weight: 600;
display: inline-flex;
line-height: 0px;
font-size: 14px;
justify-content: center;
align-items: center;
border-radius: 50%;
}
.notification p{
font-size: 13px;
color: #fff;
margin: 6px 20px 5px 5px;
padding: 0px;
}
.form-check-input
{
width: 23px;
height: 23px;
border-radius: 6px;
margin-right: 8px;
position: relative;
display: inline-block;
}
.bin
{
margin-top: 4px;
}
.mail-grid
{
position: relative;
overflow: hidden;
}

.mail-grid a
{
display:flex;
text-decoration: none;
cursor: pointer;
flex-wrap: nowrap;
justify-content: space-between;
color: #fff;
padding: 20px;
gap: 10px;
border-bottom: 1px solid #8C62DB;
position: relative;
-webkit-transition: all 1s;
-ms-transition: all 1s;
transition: all 1s;
}

.mail-grid a:hover
{
background: #3B1D73;
}
.mail-grid.active a 
{
background: #3B1D73 !important;
}
.mail-grid h4
{
font-size: 16px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
width: 240px;
}
.mail-grid p
{
font-size: 14px;
margin: 0px;
}
.mail-grid .date-and-func
{
display: grid;
position: relative;
min-width: 35px;
}
.mail-grid .date-and-func span{
font-size: 12px;

}
.bin-width
{
width: 20px;
}
.right-side-content
{
position: relative;
margin: 30px;
}
.subject-div
{
width: 100%;
padding: 20px;
margin: 0;
background: #F4F4F4;
}
.subject-div h4{
margin: 0;
font-size: 22px;
font-weight: 700;
}
.font-bold
{
font-weight: 600;
color: #6A6A6A;
font-size: 18px;
padding-top: 10px;
margin-bottom: 0px;
}
.small-text

{
font-size: 14px;
color: #6A6A6A;
padding-top: 10px;
}
.bottom-to-details
{
padding: 10px 20px;
border-bottom: 1px solid #e1e1e1;
}
.margin-0
{
margin: 0;
}
.conten-area
{
padding: 20px 20px;
}
